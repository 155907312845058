import React from 'react'
import Style from "./menu.module.css";
import LeftArrow from "../../assets/svg/leftArrow.svg"
import BadgeIcon from "../../assets/svg/badge.svg";
import NotesIcon from "../../assets/svg/notes.svg";
import BallanceIcon from "../../assets/svg/balance.svg";
import ConditionIcon from "../../assets/svg/condition.svg";
import SettingIcon from "../../assets/svg/setting.svg";
import LogoutIcon from "../../assets/svg/logout.svg";
import {Link} from "react-router-dom";
import {useSelector } from "react-redux";
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IMAGE_BASE_URL } from '../../api/service';
import ReactImageFallback from "react-image-fallback";
import Total from "../../assets/organization/ic_organization.png";
import Loader from "../../assets/images/loader.jpg";

function Menu() {
   const data = useSelector((state)=>state.users);

   const logoImage=(logo)=>{
      let baseUrl= IMAGE_BASE_URL+'users/'+logo;
      return baseUrl;
   }
   return (
      <>
        <div className={Style.topWrapper}>
           <div className={Style.topMenu}>
           <div className={Style.topMenuRight}>
                 <img src={LeftArrow} alt="leftArrow" />
              </div>
              <Link to="/updateAccount">
               <div className={Style.topMenuLeft}>
               <ReactImageFallback
                    src={logoImage(data.user_image)}
                    fallbackImage={Total}
                    initialImage={Loader}
                    className={Style.userProfile} />
                  <span className={Style.userName}>{data.firstname} {data.lastname}</span>
               </div>
              </Link>
           </div>
           <Link to="/notification">
           <div className={Style.middleMenu}>
              <div className={Style.leftMiddleMenu}>
                 <div className={Style.leftText}>Notifications</div>
              </div>
              <div className={Style.rightMiddleMenu}>
              <Badge badgeContent={0} color="error" overlap="circular" max={99}>
      <NotificationsNoneIcon className={Style.bellIcon} />
    </Badge>
                 {/* <img src={BellIcon} alt="bellIcon" /> */}
              </div>
           </div>
           </Link>
           <div className={Style.bottomMenu}>
           <Link to="/general">
               <div className={Style.bottomText}>General</div>
           </Link>
              <div className={Style.bottomText}>Contact Us</div>
           </div>
        </div> 
        <Link to="/myVehicle">
        <div className={Style.vehicle}> 
              <div className={Style.textVehicle}>{data.vehicles[0].make.make} {data.vehicles[0].model?data.vehicles[0].model.model:""}</div>
              <span className={Style.imgText}>{data.vehicles[0].state} {data.vehicles[0].registration_number}</span>
              <div className={`d-flex align-items-center`}>
              <div className={Style.numberPlate}>{data.vehicles[0].type.type}</div>
              <div style={{backgroundColor: data.vehicles[0].color.color}}  className={Style.color}></div>
              </div>
              
        </div>
        </Link>
        <Link to="/services" >
        <div className={Style.favorites}>
           <div className={Style.leftFavorite}>
              <div className={Style.heading}>Favorites</div>
              <div className={Style.description}>Manage your favorites, set your home and work location.Your favorite locationand places.</div>
           </div>
           <div className={Style.rightFavorie}>
              <img src={BadgeIcon} alt="icon" />
           </div>
        </div>
        </Link>
        <div className={Style.favorites}>
         <Link to="/updateAccount">
           <div className={Style.leftFavorite}>
              <div className={Style.heading}>Personal</div>
              <div className={Style.description}>Manage your personal info, preferences and decisions.Add your personal loram keep track of your serviceshistory, coupons and packages.
              </div>
           </div>
           </Link>

           <div className={Style.rightFavorie}>
              <img src={NotesIcon} alt="icon" />
           </div>
        </div>
        <div className={Style.Ballance}>
           <Link to='/'>
               <img src={SettingIcon} alt="setting" />
               <div className={Style.textBallance}>Settings</div>
           </Link>
            <Link to="/legal">
               <img src={BallanceIcon} alt="balance" />
               <div className={Style.textBallance}>Legal</div>
            </Link>
            <Link to="/terms">
               <img src={ConditionIcon} alt="notes" />
               <div className={Style.textBallance}>Terms of services</div>
            </Link>
        </div>
        <Link to="/">
        <div className={Style.logout}>
           <img src={LogoutIcon} alt="logout" />
           <div className={Style.rightVehicle}>
              <div className={Style.textVehicle}>Logout</div>
           </div>
        </div>
        </Link>
      </>
   )
}

export default Menu
