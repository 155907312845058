import React, {useState,useEffect} from 'react'
import BlackArrow from '../../assets/svg/leftArrowBlack.svg'
import {Link} from 'react-router-dom'
import NotificationCard from '../../components/notificationCard/NotificationCard'
import {Modal} from 'react-bootstrap';
import { notificationList,bookingDetail } from '../../api/service';
import Spinner from '../../components/spinner/index';
import {useSelector } from "react-redux";
import BookingComponent from "../../components/bookingCards/BookingCards";

function Notification() {
   const [listing, setListing] = useState([]);
   const [booking, setbookingDetail] = useState([]);
   const [loading, setLoading] = useState(true);
   const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const data = useSelector((state)=>{
   return state.users
  })
  const notificationListApi=()=>{
   let object={
      user_id:data._id
   }
   notificationList(object).then(responce => {
      console.log(responce.Data);
      const listed=responce.Data;
      setListing(listed);
      setLoading(false);

    });
  }


  const detailBooking=(data)=>{
     let object={
        booking_id:data.booking_id
     } 
   bookingDetail(object).then(responce => {
      setbookingDetail(responce.Data[0]);
      handleShow(true);
    });
  }
useEffect(() => { 
   notificationListApi();
 },[])
   return (
      <>
         <div className="topHeading d-flex align-items-center">
         <Link to="/menu">
            <img src={BlackArrow} alt="" />
         </Link>
            <div className="pl-4">Notification</div>
         </div>
         <div className="dummyText">
         {/* {listing.length<=0 && !loading  ?<div >No record found</div>:null} */}
       
         { loading ? <Spinner /> : null }

           {listing.map(function(object, i){
            return (
            <>
            <div onClick={()=>{detailBooking(object)}} className="cursorPointer">
            <NotificationCard cancelButton={true}  obj={object} key={i} 
            />
            </div>
            </>
            )
            })}
         <Modal size="md" centered scrollable show={show} onHide={handleClose} className="notificationModal">
               <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter">
          Booking Detail
        </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <BookingComponent obj={booking}></BookingComponent>
               </Modal.Body>
               </Modal>
         </div>
      </>
   )
}

export default Notification
