import React from 'react'
import Style from './userCard.module.css'
import Profile from '../../assets/images/profile.png'

function UserCard() {
   return (
      <>
        <div className={Style.userCard}>
         <div className={`d-flex`}>
               <img src={Profile} alt="profile" className={Style.profile} />
               <div className={`pl-3`}>
                  <div className={Style.userName}>Mohsin Mughal</div>
                  <div className={Style.userNumber}>+971598765434</div>
               </div>
         </div> 
         <div className={`d-flex py-3 justify-content-between`}>
            <div className={Style.bottomWrapperText}>
               <div>Suzuki Swift</div>
               <div>12345</div>
               <div>Punjab</div>
            </div>
            <div className={Style.bottomWrapperText}>
               <div>Sedan</div>
               <div>White</div>
            </div>
         </div>
        </div>
      </>
   )
}

export default UserCard
