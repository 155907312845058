import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const config = {
  apiKey: "AIzaSyC3ynAWeSNQuL04OFVeqPFLG_tCObo6EuI",
  authDomain: "shehnshahbusiness.firebaseapp.com",
  databaseURL: "https://shehnshahbusiness.firebaseio.com",
  projectId: "shehnshahbusiness",
  storageBucket: "shehnshahbusiness.appspot.com",
  messagingSenderId: "576234340760",
  appId: "1:576234340760:web:f6b91dd93acd54709e6bed",
  measurementId: "G-4G7L7M4GWN",
};
firebase.initializeApp(config);
export default firebase;
