
import Style from "./services.module.css";
import ServiceCards from "../../components/serviceCards/ServiceCards";
import Spinner from '../../components/spinner/index'
import { serviceProvider } from '../../api/service';
import React, { useEffect, useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import {useSelector } from "react-redux";


function Services() {
   const data = useSelector((state)=>state.users);
   const [list, setList] = useState([]);
   const [tempList, settempList] = useState([]);
   const [loading, setLoading] = useState(true);
   const [favorites, setFavorites] = useState(true);

   const HandleFavorites = () => {
      
      setFavorites(false);
      if(favorites){
      let listed = tempList.filter(item => data.favouriteOrganizations.includes(item._id));
      setList(listed);
      setFavorites(false);
      }
      else{
         let listed = tempList.filter(item => !data.favouriteOrganizations.includes(item._id));
         setList(listed);
         setFavorites(true);
      }
   }

   useEffect(() => {
      serviceProvider().then(responce => {
         const listed=responce.Data.organizations;
         settempList(listed);
         setList(listed);
         setLoading(false);
   
       });
        
    }, [])


   return (
      <>
         <div className={Style.home}>

            <div className={Style.topHeading}>
               <div>Service Providers</div>
            <div onClick={() => HandleFavorites()}>
            {favorites ? <FaRegHeart className={Style.fontClass} /> : <FaHeart className={Style.fontClass} />}
            </div>
            </div>
         <div className={Style.cardMain}>
         { loading ? <div className={Style.spinnerDiv}><Spinner /></div> : null }

            {list.map(function(object, i){

        return (
        <>
         <div key={ Math.random().toString(36).substr(2, 9) } >
        <ServiceCards obj={object}     
        />
         </div>
        </>
        )
})}
         </div>
      </div>
         
      </>
   )
}

export default Services
