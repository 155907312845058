import {React, useState} from 'react';
import Style from './bookings.module.css';
import 'react-phone-number-input/style.css';
import ReactCodeInput from 'react-verification-code-input';
import Flag from '../../assets/images/flag.png';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import UserCard from '../../components/userCard/UserCard';




function Bookings() {

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const detailBooking=(data)=>{ 
          handleShow(true);
   }


   
   
   return (
      <>
        <div className={Style.home}>
            <div className={Style.topHeading}>Booking</div>
               <div className={Style.parentDiv}>
                  <div className={Style.label}>Enter customer phone number</div>
                  <div className={Style.imgDiv}><img src={Flag} alt="flag" /> 971 </div>
                  <div className={`position-relative`}>
                     <div className={Style.customBox}>5</div>
                     <ReactCodeInput fields={8} className={`d-flex justify-content-center mt-4 w-100 customLeft`} />
                  </div> 
                  <div className={`${Style.label} pt-3`}>Enter registeration number</div>
                  <ReactCodeInput fields={5} className={`d-flex justify-content-center mt-4 w-100`} />
                  <div className={`d-flex flex-column align-items-center justify-content-center pt-4`}>
                     <button onClick={()=>{detailBooking()}} className="btnBlack">SEARCH</button>
                     <Link to="/newCustomer" className={Style.registerUrl}>Register new customer</Link>
                  </div>
                  <Modal size="md" centered scrollable show={show} onHide={handleClose}>
                  <Modal.Header closeButton></Modal.Header>
               <Modal.Body>
                  <Link to="/detail"><UserCard /></Link>
                  <UserCard />
                  <UserCard />
                  <UserCard />
               </Modal.Body>
               </Modal>
               </div>     
         </div>
      </>
   )
}

export default Bookings

