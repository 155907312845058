import React, { useEffect, useState } from 'react';
import Style from "./otp.module.css";
import Flag from '../../assets/images/flag.png'
import 'react-phone-number-input/style.css';
import { useHistory, Link } from "react-router-dom";
import firebase from '../../firebase'
import ReactCodeInput from 'react-verification-code-input';
import { checkUserExist } from '../../api/service';
import {login} from '../../_actions/index'
import {useDispatch} from 'react-redux';
import Loader from "react-loader-spinner";
import Arrow from '../../assets/svg/leftArrowBlack.svg';
import {showError} from '../../global/globalFunction';

function Otp() {
  const history = useHistory();
  const dispatch = useDispatch();

    const [phNo, setValue] = useState('');
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('Enter your mobile number');
    const [description, setDescription] = useState('Enter your number to create an account or log in');
    const [sentOtp, setOtp] = useState(true);
    const [loader, setButtonLoader] = useState(false);
    const [EditNumber, setEditNumber] = useState(false);
    const [mobile, setMobile] = useState([2]);
    const [arrow, setArrow] = useState(false);
    const [appVerifier,setappVerifier] = useState()
    useEffect(()=>{
      let v =  new firebase.auth.RecaptchaVerifier('recaptcha',{
        'size': 'invisible',
    });
    setappVerifier(v)
    },[])
    const HomeUrl = () => {
      setValue('');
      setOtp(true);
      setEditNumber(false);
      setTitle('Enter your mobile number');
      setDescription('Enter your number to create an account or log in');
      setArrow(false);
    }


   
    const handleClick = (e) => {
       setButtonLoader(true)
        const phoneNumber = '+'+9715 + phNo;
        setMobile(phoneNumber);
    
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              setOtp(false);
              setButtonLoader(false)
              setEditNumber(true)
              setTitle('Verify your mobile number');
              setDescription('Enter the pin you have received via SMS on '+ phoneNumber);
              
              // ...
            }).catch((error) => {
              setButtonLoader(false)
              if(error.code==='auth/invalid-phone-number'){
                showError("invalid-phone-number");return ;
              }
              alert(error);
            });
      }

     const verifyOtp = (e) =>{
      setButtonLoader(true)

        window.confirmationResult.confirm(code).then((result) => {

            let object ={
                phone:mobile
            }
            checkUserExist(object).then(responce => {
              console.log(responce)
              setButtonLoader(false)
                if(responce.Data.is_exist){
                  dispatch(login(responce.Data.user));
                  localStorage.setItem('user', JSON.stringify(responce.Data.user));
                   history.push({
                      pathname: '/home',
                    })
                }else{
                  history.push({
                    pathname: '/register',
                    state: { pNo: mobile }
                  })
                }
          
              });
          // User signed in successfully.
          const user = result.user;
          console.log(JSON.stringify(user))
          // ...
        }).catch((error) => {
            setCode('')
            setButtonLoader(false)
            showError('Please enter a valid code')
          // User couldn't sign in (bad verification code?)
          // ...
        });
      }
  
    return (
        <div className={Style.parentDiv}>
          <div className="customContainer">
          {sentOtp ? '' : <img src={Arrow} alt="arrow" className={Style.arrowCursor} onClick={()=> {HomeUrl()}} />}
                <div className={`${Style.heading} pt-3`}>{title}</div>
                <div className={`${Style.subHeading} pt-2`}>{description}<br/ >{EditNumber ? <span onClick={()=> {HomeUrl()}} className={Style.editNumber}>Edit Number</span> : ''}</div>
            <div id="recaptcha"></div>
         { sentOtp ? 
        <>
         <div className={Style.imgDiv}>
          <img src={Flag} alt="flag" /> 971 </div>
          <div className={`position-relative`}><div className={Style.customBox}>5</div>
            <ReactCodeInput value={mobile} onChange={setValue}  fields={8} className={`d-flex justify-content-center mt-4 w-100 customLeft`} />
          </div>
        </>
             : <ReactCodeInput value={code} onChange={setCode}  fields={6} className={`d-flex justify-content-center mt-5 w-100`} /> }

            
            <div className={`d-flex align-items-center justify-content-center flex-column pt-5`}>
            {
             sentOtp ? 
             <><button className="btnBlack" onClick={()=>{handleClick()}}>NEXT
             {
               loader ? 
             <span>
               <Loader
             type="Circles"
             color="#00BFFF"
             height={27}
             width={27}
           /></span>:null
             }
           </button><Link to="/register"><div className={Style.registerText}>Register new business</div></Link></>
           :<><button className="btnBlack" onClick={()=>{verifyOtp()}}>VERIFY
           {
               loader ? 
             <span>
               <Loader
             type="Circles"
             color="#00BFFF"
             height={27}
             width={27}
           /></span>:null
             }</button><div className={Style.otpText}>If you don't receive a code! <span onClick={()=>handleClick()}>Resend</span></div></>
            }
            </div>
            
            
        </div>
        </div>

    )
}

export default Otp;
