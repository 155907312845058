export const IMAGE_BASE_URL  = "http://shehnshah-backend.crunchyapps.com:5000/images/";
var baseUrl='http://shehnshah-backend.crunchyapps.com:5000/api';

export async function getServiceList() {
    return await fetch(baseUrl+'/bookings/getListing')
      .then(data => data.json())
  }

  export async function setting() {
    return await fetch(baseUrl+'/settings')
      .then(data => data.json())
  }

  export async function serviceProvider() {
    return await fetch(baseUrl+'/organizations')
      .then(data => data.json())
  }

  export async function availabelSlots(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ serviceOfferingID: object.service_id,organizationID:object.organization_id,date:object.date })
  };
    return await fetch(baseUrl+'/organizations/timeslots',requestOptions)
      .then(data => data.json())
  }

  export async function checkUserExist(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: object.phone})
  };
    return await fetch(baseUrl+'/users/userStatus',requestOptions)
      .then(data => data.json())
  }

  export async function notificationList(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: object.user_id})
  };
    return await fetch(baseUrl+'/notifications/user_notifcation_list',requestOptions)
      .then(data => data.json())
  }

  export async function bookingDetail(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ booking_id: object.booking_id})
  };
    return await fetch(baseUrl+'/bookings/booking_detail',requestOptions)
      .then(data => data.json())
  }

  export async function updateVehicle(data) {
    const formData = new FormData();
    formData.append("_id", data._id);
    formData.append("email",data.email);
    formData.append("phone", data.phone);
    formData.append("firstname", data.firstname);
    formData.append("lastname",data.lastname);
    formData.append("username",data.username);
    formData.append("color",data.color);
    formData.append("make",data.make);
    formData.append("type",data.type);
    formData.append("registration_number",data.registration_number);
    formData.append("state",data.state);
    formData.append("vin",data.vin);
    formData.append("year",data.year);
    formData.append("user_image",data.user_image);
    const requestOptions = {
      method: 'POST',
      body: formData
  };
    return await fetch(baseUrl+'/users/updateUserData',requestOptions)
      .then(data => data.json())
  }

  export async function addUserData(data) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { 
          email:data.email,
          phone:data.phone,
          firstname:data.firstname, 
          lastname:data.lastname, 
          username: data.username,
          color: data.color,
          make: data.make,
          type: data.type,
          registration_number: data.registration_number,
          state: data.state,
          vin: data.vin,
          year: data.year,
        })
  };
    return await fetch(baseUrl+'/users/addUserData',requestOptions)
      .then(data => data.json())
  }

  export async function createBooking(data) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { 
          organization_id: data.organization_id,
          serviceOffering_id:data.serviceOffering_id,
          time_from:data.time_from,
          queue_id:data.queue_id,
          time_to:data.time_to, 
          date:data.date, 
          user_id: data.user_id,
          auth_user_id: data.user_id,
          vehicle_id: data.vehicle_id,
        })
  };
    return await fetch(baseUrl+'/bookings/createBooking',requestOptions)
      .then(data => data.json())
  }

  export async function bookingList(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: object.user_id})
  };
    return await fetch(baseUrl+'/users/bookingHistory',requestOptions)
      .then(data => data.json())
  }

  export async function bookingStatus(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { 
          auth_user_id: object.user_id,
          booking_id: object.booking_id,
          status_id:object.status_id
        }
          )
  };
    return await fetch(baseUrl+'/bookings/bookingStatus',requestOptions)
      .then(data => data.json())
  }


  export async function addToFavourite(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { 
          user_id: object.user_id,
          organization_id: object.organization_id,
        }
          )
  };
    return await fetch(baseUrl+'/users/addToFavourite',requestOptions)
      .then(data => data.json())
  }

  export async function removeFromFavourite(object) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { 
          user_id: object.user_id,
          organization_id: object.organization_id,
        }
          )
  };
    return await fetch(baseUrl+'/users/removeFromFavourite',requestOptions)
      .then(data => data.json())
  }

//   const onlyUnique=(arr, key)=>{
//     return [...new Map(arr.map(item =>
//         [item[key]['title'], item])).values()];
       
//  }

  
 