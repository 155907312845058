import React from "react";
import Verification from "./pages/verification/Verification";
import Otp from "./pages/otp/Otp";
import "./App.css";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Services from "./pages/servicesProvider/Services";
import ServiceDetail from "./pages/serviceDetail/ServiceDetail";
import Home from "./pages/home/Home";
import BookingSlot from "./pages/bookingSlots/BookingSlot";
import Navbar from "./components/navbar/Navbar";
import Booking from "./pages/booking/Booking";
import Register from "./components/registerForm/RegisterForm";
import Menu from "./pages/menu/Menu";
import UpdateAccount from "./pages/updateAccount/UpdateAccount";
import ConfirmBooking from "./pages/confirmBooking/ConfirmBooking";
import Favorites from "./pages/favorites/Favorites";
import Bookings from "./pages/bookings/Bookings";
import MyVehicle from "./pages/myVehicle/MyVehicle";
import { PrivateRoute } from "./components/PrivateRoute";
import { store } from "./_helper/store";
import { Provider } from "react-redux";
import { useHistory } from "react-router-dom";
import Legal from "./pages/legal/Legal";
import Notification from "./pages/notification/Notification";
import General from "./pages/general/General";
import Terms from "./pages/terms/Terms";
import CustomerForm from './components/customerForm/CustomerForm'

function App() {
  const history = useHistory();


  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Otp />
          </Route>
          <Route path="/verification">
            <Verification />
          </Route>
          <div className="fullContainer">
            <Navbar />
            <div className="pb-85">
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/booking">
                <Booking />
              </Route>
              <PrivateRoute exact path="/bookings" component={Bookings} />

              <Route path="/favorites">
                <Favorites />
              </Route>
              <PrivateRoute exact path="/services" component={Services} />
              <Route path="/detail">
                <ServiceDetail />
              </Route>
              <Route path="/slots">
                <BookingSlot />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/menu">
                <Menu />
              </Route>
              <Route path="/updateAccount">
                <UpdateAccount />
              </Route>
              <Route path="/confirmBooking">
                <ConfirmBooking />
              </Route>
              <Route path="/myVehicle">
                <MyVehicle />
              </Route>
              <Route path="/legal">
                <Legal />
              </Route>
              <Route path="/notification">
                <Notification />
              </Route>
              <Route path="/general">
                <General />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/newCustomer">
                <CustomerForm />
              </Route>
            </div>
          </div>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
