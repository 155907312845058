import React from 'react';
import Style from "./homeCard.module.css";
import ProfilePic from "../../assets/images/profilePic.png";
import PieGraph from  "../../assets/svg/pieGraph.svg"
import CarWash from "../../assets/svg/car-wash.svg";
import OilChange from "../../assets/svg/oil-change.svg";
import Battery from "../../assets/svg/battery.svg";

function HomeCard() {
   return (
      <>
         <div className={Style.mainCardWrapper}>
         <div className={Style.bookingWrapper}>
            <div className={Style.rightbookingWrapper}>
               05:50PM to 09:00PM
            </div>
            <button className={Style.bookBtn}>Book</button>
         </div>
            <div className={Style.cardWrapper}>
               <div className={`d-flex`}>
                  <div className={Style.leftTopCard}>
                     <img src={ProfilePic} alt="profileImg" className={Style.profile} />
                     <div className={`d-flex flex-column pl-2`}>
                        <div className={Style.userName}>Zaka Shah</div>
                        <div className={Style.userEmail}>+971598765434</div>
                        <div className={Style.userTime}>07:00 PM to 07:45PM</div>
                     </div>
                  </div>
                  <div className={Style.rightTopCard}>
                     <img src={PieGraph} alt="" className={Style.rightTopCardImg} />
                  </div>
               </div> 
               <div className={Style.middleCard}>
                  <div className={Style.middleLeft}>
                     <div className="detailCard">
                        <img src={CarWash} className={Style.bottomWrapperImg} alt="" /> 
                        <span className={Style.itemName}>Car Wash</span>
                     </div>
                     <div className={Style.cardText}>Full Wash</div>
                  </div>
                  <div className={Style.middleBorder}></div>
                  <div className={Style.middleRight}>
                     <div className={Style.middleLabel}>Suzuki Suzuki Swift</div>
                     <div className={Style.middleText}>Punjab 12345</div>
                     <div className={Style.middleText}>Sedan <div className={Style.carColor}></div></div>
                  </div>
               </div>
               <div className={Style.bottomCard}>
                     <button className={Style.btnBook}>Booked</button>
                     <button className={Style.btnStart}>Start</button>
                     <button className={Style.btnCancel}>No Show</button>
               </div>
            </div>
         </div>
      </>
   )
}

export default HomeCard
