import React, {useState} from 'react'
import Style from "./updateAccount.module.css"
import ArrowWhite from "../../assets/svg/leftArrow.svg"
import ProfilePic from "../../assets/images/profilePic.png"
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {useSelector } from "react-redux";
import {updateVehicle } from '../../api/service';
import {useDispatch} from 'react-redux';
import {login} from '../../_actions/index';
import {showSuccess} from '../../global/globalFunction';
import Loader from "react-loader-spinner";
import { IMAGE_BASE_URL } from '../../api/service';
import ReactImageFallback from "react-image-fallback";
function UpdateAccount() {
   const logoImage=(logo)=>{
      let baseUrl= IMAGE_BASE_URL+'users/'+logo;
      return baseUrl;
   }
   const userObject = useSelector((state)=>state.users);
   const [imgRender, setImgRender] = useState(logoImage(userObject.user_image));
   const [value, setValue] = useState('');
   const [loader, setButtonLoader] = useState(false);
   const vehicleData=userObject.vehicles[0];
   const dispatch = useDispatch();

   const handleChange = (event) => {
      setImgRender(URL.createObjectURL(event.target.files[0]));
   }
   
  
   const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
       let object={ 
          _id: userObject._id,
          email:data.get('email'),
          phone:userObject.phone,
          firstname:data.get('fname'), 
          lastname:data.get('lname'), 
          username: userObject.username,
          color: vehicleData.color._id,
          make: vehicleData.make._id,
          model: data.get('model'),
          type: vehicleData.type._id,
          registration_number: vehicleData.registration_number,
          state:vehicleData.state,
          vin: vehicleData.vin,
          year: vehicleData.year._id,
          user_image:data.get('user_image'),
        }
        setButtonLoader(true);
        updateVehicle(object).then(responce => {
         if(responce.Status===200){
            showSuccess("User Information Updated Successfully");
            // showSuccess(responce.Message);
            dispatch(login(responce.Data));
            localStorage.setItem('user', JSON.stringify(responce.Data));
            setButtonLoader(false);
          }
       });

    };

   return (
      <>
         <div className={Style.topWrapper}>
            <Link to="/menu">
            <img src={ArrowWhite} alt="" />
            </Link>
            <div className={Style.topWrapperText}>Personal Info</div>
         </div>
         <form onSubmit={handleSubmit} enctype='multipart/form-data' >
         <div className={Style.imgWrapper}>
            <div className={Style.profileImg}>
            <ReactImageFallback
                    src={imgRender}
                    fallbackImage={ProfilePic}
                    initialImage={Loader} />
               <input type="file" name="user_image" onChange={ (event) => handleChange(event) } />
            </div>
         </div>
         <div className={Style.formText}>
         Your info makes service providers<br className={Style.hide} /> to identify you
         </div>

         <div className={Style.formWrapper}>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>First Name</div>
               <input type="text" defaultValue={userObject.firstname} name="fname" id="fname" autoComplete="off" placeholder="Zaka" />
            </div>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>Last Name</div>
               <input type="text" name="lname" defaultValue={userObject.lastname} id="lname" autoComplete="off" placeholder="Zaka" />
            </div>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>Email</div>
               <input type="email" name="email" defaultValue={userObject.email} id="email" autoComplete="off" placeholder="Zaka" />
            </div>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>Phone</div>
               <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="AE"
                value={userObject.phone}
                onChange={setValue} 
                disabled
                className={`${Style.PhoneInput}`}
            />
            </div>
            <div className={`text-center mt-4 mb-3`}>

            <button className="btnBlack" type="submit" >Update
           {
               loader ? 
             <span>
               <Loader
             type="Circles"
             color="#00BFFF"
             height={27}
             width={27}
           /></span>:null
             }</button>
         </div>
         </div>
         </form>

      </>
   )
}

export default UpdateAccount
