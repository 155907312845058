import React from 'react'
import Style from './notification.module.css'

function NotificationCard(prop) {
   
   return (
      <>
        <div className={Style.cardWrapper}>
           <div className={Style.heading}>
              {prop.obj.sender_name}
           </div>
           <div className={Style.description}>
           Your Booking is <span className={Style.heading}>{prop.obj.action_perform}</span>
           </div>
        </div> 
      </>
   )
}

export default NotificationCard
