import React from 'react'
import Style from  "./navbar.module.css";
import {NavLink} from "react-router-dom";
import Place from "../../assets/svg/placeBlue.svg";
import BookingSvg from "../../assets/svg/bookingBlue.svg";
import MenuSvg from "../../assets/svg/menuBlue.svg";


function Navbar() {
   return (
      <>
         <nav className={Style.navMenu}>
            <ul className={Style.navList}>
               <NavLink to="/home" className={Style.navLink} activeClassName={Style.navListActive}>
               <li className={Style.navListItem}>
                  <img src= {Place} alt="places" className={Style.navImg} /> <span className={Style.itemName}>Timeline</span>
               </li>
               </NavLink>
               <NavLink to="/bookings" className={Style.navLink} activeClassName={Style.navListActive}>
               <li className={Style.navListItem}>
                  <img src={BookingSvg} alt="booking" className={Style.navImg} /> <span className={Style.itemName}>Booking</span>
               </li>
               </NavLink>
               <NavLink to="/menu" className={Style.navLink} activeClassName={Style.navListActive}>
               <li className={Style.navListItem}>
                  <img src={MenuSvg} alt="menu" className={Style.navImg} /> <span className={Style.itemName}>Menu</span>
               </li>
               </NavLink>
            </ul>
         </nav>
      </>
   )
}

export default Navbar;
